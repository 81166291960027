// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ClerkReact from "@clerk/clerk-react";

var Provider = {};

var SignedIn = {};

var SignedOut = {};

var SignInButton = {};

var SignOutButton = {};

var MenuItems = {};

var Action = {};

var UserButton = {
  MenuItems: MenuItems,
  Action: Action
};

var Pagination = {};

var Hook = {};

function use(prim) {
  return ClerkReact.useUser();
}

var User = {
  Hook: Hook,
  use: use
};

var getTokenRef = {
  contents: undefined
};

function use$1() {
  var match = ClerkReact.useAuth();
  return {
          getToken: match.getToken,
          userId: Caml_option.null_to_opt(match.userId),
          orgSlug: Caml_option.null_to_opt(match.orgSlug),
          orgId: Caml_option.null_to_opt(match.orgId),
          orgRole: Caml_option.null_to_opt(match.orgRole),
          sessionId: Caml_option.null_to_opt(match.sessionId),
          isSignedIn: match.isSignedIn,
          isLoaded: match.isLoaded
        };
}

var Auth = {
  getTokenRef: getTokenRef,
  use: use$1
};

function use$2(params) {
  var v = ClerkReact.useOrganization(params);
  var organization = v.organization;
  if (v.isLoaded) {
    if (organization === null) {
      return {
              TAG: "Loaded",
              _0: undefined
            };
    } else {
      return {
              TAG: "Loaded",
              _0: {
                organization: organization,
                memberships: v.memberships
              }
            };
    }
  } else {
    return "Loading";
  }
}

var Organization = {
  use: use$2
};

var OrganizationList = {};

var Hooks = {
  Auth: Auth,
  Organization: Organization,
  OrganizationList: OrganizationList
};

var OrganizationSwitcher = {};

var UseClerk = {};

var getCookie = (function getCookie(name) {
        const separator = "; ";
        const equalSign = "=";
        
        const value = separator + document.cookie;
        const parts = value.split(separator + name + equalSign);
        
        if (parts.length === 2) {
          return parts.pop().split(separator)[0];
        }
      });

function make() {
  return getCookie("clerk_active_org");
}

var GetActiveOrgUnsafe = {
  getCookie: getCookie,
  make: make
};

export {
  Provider ,
  SignedIn ,
  SignedOut ,
  SignInButton ,
  SignOutButton ,
  UserButton ,
  Pagination ,
  User ,
  Hooks ,
  OrganizationSwitcher ,
  UseClerk ,
  GetActiveOrgUnsafe ,
}
/* @clerk/clerk-react Not a pure module */
