// Generated by ReScript, PLEASE EDIT WITH CARE


function parse(s) {
  switch (s) {
    case "chats-list" :
        return "conversations";
    case "template-details" :
        return "templatesDetails";
    case "templates-list" :
        return "templatesList";
    default:
      return ;
  }
}

function serialize(t) {
  if (t === "templatesDetails") {
    return "template-details";
  } else if (t === "conversations") {
    return "chats-list";
  } else {
    return "templates-list";
  }
}

var $$default = "conversations";

export {
  parse ,
  serialize ,
  $$default as default,
}
/* No side effect */
